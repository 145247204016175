import React, { useEffect } from "react";
import { useState } from "react";
import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { Colors } from "../../styles/styleConstants";
import { FaCheck, FaInfo } from "react-icons/fa";
import { fbpLeadTracking, gtmDataLayerPush } from "../../utils/tracking";
import { StringParam, useQueryParam } from "use-query-params";
import {
  getUserMessage,
  submitEarlyAccess,
  isErrorInUserMessage,
  isRequestSuccess,
} from "../../utils/apiUtils";
import {
  EmailShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import emailIcon from "../../images/email.png";
import twitterIcon from "../../images/twitter.png";
import linkedInIcon from "../../images/linkedin.png";
import {
  FIVE_SECONDS,
  QUERY_PARAMS,
  TARGET_SERVICE,
} from "../../utils/commonConst";
import {
  getTotalWaitlistText,
  isEmpty,
  parseReqParams,
} from "../../utils/commonUtil";

export default function WaitingListModal(props) {
  const {
    show,
    handleClose,
    referralCode = undefined,
    ctaText = "Get Early Access",
    signupCode = "",
  } = props ?? {};
  const [validated, setValidated] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  // Fields
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [linkedInUrl, setLinkedInURL] = useState("");
  const [howDidYouHear, setHowDidYouHear] = useState(signupCode);
  const [waitingListData, setWaitingListData] = useState({});

  const trackSubmission = (event) => {
    gtmDataLayerPush(event, "waitlist");
  };

  let referralCodeDerived = referralCode;

  const sharableLink = `https://www.uprise.us/?ref=${waitingListData?.referralCode}`;

  if (isEmpty(referralCodeDerived)) {
    referralCodeDerived = parseReqParams(QUERY_PARAMS.REF);
  }
  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, FIVE_SECONDS);
    }
  }, [isCopied]);

  const submitSignupToMailChimp = () => {
    addToMailchimp(email, {
      FNAME: firstName,
      LNAME: lastName,
      MMERGE9: howDidYouHear,
      MMERGE10: referralCodeDerived,
    })
      .then((mailChimpRes) => {
        const isSuccess = isRequestSuccess(
          mailChimpRes,
          TARGET_SERVICE.MAIL_CHIMP
        );
        if (isSuccess) {
          setFormSent(true);
          setWaitingListData(submitQueryRes.data.data);
          trackSubmission("waitlist-mailchimp-submission");
        } else {
          const message = getUserMessage(mailChimpRes, email);
          if (isErrorInUserMessage(message)) {
            setFormError(true);
            setFormErrorMessage(message, email);
            trackSubmission("waitlist-mailChimp-error");
          }
        }
      })
      .catch((exception) => {
        trackSubmission("waitlist-mailChimp-unexpected-error");
      });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      setSubmitting(true);
      submitEarlyAccess({
        firstName,
        lastName,
        email,
        linkedInUrl,
        howDidYouHear,
        referrerReferralCode: referralCodeDerived,
      })
        .then((submitQueryRes) => {
          const isSuccess = isRequestSuccess(
            submitQueryRes,
            TARGET_SERVICE.UPRISE
          );
          if (isSuccess) {
            setFormSent(true);
            setWaitingListData(submitQueryRes.data.data);
            trackSubmission("waitlist-submission");
            fbpLeadTracking();
          } else {
            const message = getUserMessage(submitQueryRes, email);
            if (isErrorInUserMessage(message)) {
              setFormError(true);
              setFormErrorMessage(message, email);
              trackSubmission("waitlist-error");
            }
            // else {
            //   trackSubmission("unexpected error");
            //   submitSignupToMailChimp();
            // }
          }
          setSubmitting(false);
        })
        .catch((exception) => {
          const message = getUserMessage(exception, email);
          if (isErrorInUserMessage(message)) {
            setFormError(true);
            setFormErrorMessage(message, email);
            trackSubmission("waitlist-error");
          }
          // else {
          //   trackSubmission("unexpected error");
          //   submitSignupToMailChimp();
          // }
        });
    }
    setValidated(true);
  };

  const {
    position: currentPosition,
    totalWaitlistMembers,
    priorityAccessEntity,
  } = waitingListData ?? {};
  const waitListPositionText =
    currentPosition - 1 > 0 ? (
      <CommonP>{getTotalWaitlistText(totalWaitlistMembers)}</CommonP>
    ) : (
      <CommonP>You're first in line!</CommonP>
    );
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <GlobalStyle />

        {formSent && (
          <div className="text-left successContent">
            <CheckCircle>
              <FaCheck size={30} color={Colors.white} />
            </CheckCircle>
            <h2 className="my-3">
              {priorityAccessEntity != null
                ? `You get priority access with ${priorityAccessEntity}`
                : "You’re on the list"}
            </h2>
            {currentPosition != null && waitListPositionText}
            <hr />
            <CommonP>
              {priorityAccessEntity != null
                ? "Invite friends to get even earlier access"
                : "Invite friends to get priority access"}
            </CommonP>
            <ListDescription>
              The more friends sign up through <strong>your unique link</strong>
              , the sooner you’ll get access and so will they.
            </ListDescription>
            <CopyContent>
              <LinkField value={sharableLink} />
              <CopyToClipboard text={sharableLink}>
                <CopyButton
                  isCopied={isCopied}
                  onClick={() => {
                    setIsCopied(true);
                  }}
                >
                  <CopyText>{isCopied ? "Copied!" : "Copy"}</CopyText>
                </CopyButton>
              </CopyToClipboard>
            </CopyContent>
            <SocialIconBar>
              <IconDiv>
                <SocialIconWrapper>
                  <EmailShareButton
                    url=""
                    subject="Check out Uprise - they’ll optimize your finances"
                    body={`Hey!
 
Hope all is well. I just signed up for the Uprise waitlist - they make personalized financial plans, and say they’ll add $1.5M back to your net worth. If you use this link, you can get instant access: ${sharableLink}
                     
Would love to hear what you think!
                    
                    `}
                  >
                    <SocialIcon src={emailIcon} />
                  </EmailShareButton>
                </SocialIconWrapper>
                <SocialIconWrapper>
                  <TwitterShareButton
                    url={`Check out @uprisemoney – they make personalized financial plans and say they’ll add $1.5M back to your net worth. This link will let you skip toward the front of the waitlist: ${sharableLink}`}
                    hashtags={["fintech", "personalfinance", "money"]}
                  >
                    <SocialIcon src={twitterIcon} />
                  </TwitterShareButton>
                </SocialIconWrapper>
                <SocialIconWrapper>
                  <LinkedinShareButton url={sharableLink}>
                    <SocialIcon src={linkedInIcon} />
                  </LinkedinShareButton>
                </SocialIconWrapper>
              </IconDiv>
            </SocialIconBar>
          </div>
        )}

        {formError && (
          <div className="text-center my-5">
            <WarningCircle>
              <FaInfo size={50} color={Colors.white} />
            </WarningCircle>
            <h2 className="my-3">Something went wrong</h2>
            <div dangerouslySetInnerHTML={{ __html: formErrorMessage }}></div>
          </div>
        )}

        {!formSent && !formError && (
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="waitlistForm"
          >
            <Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>First and last name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom03">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom05">
                <Form.Label>How did you hear about us?</Form.Label>
                <Form.Control
                  type="text"
                  value={howDidYouHear}
                  onChange={(event) => setHowDidYouHear(event.target.value)}
                />
              </Form.Group>
            </Row>
            <SubmitButtonWrapper>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="secondary"
                size="sm"
                className="ctaButton"
              >
                {isSubmitting ? "Submitting…" : ctaText}
              </Button>
            </SubmitButtonWrapper>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
}

const GlobalStyle = createGlobalStyle`
.waitlistForm {
  label {
    font-size: .9rem;
    margin-top: .5rem;
  }

  .form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    height: auto;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: .25rem;
    box-shadow: none !important;
    color: black;
  }
}
`;

const ListDescription = styled.p`
  font-size: 16px;
`;

const CommonP = styled.p`
  font-weight: 600;
  color: #212529;
`;

const SubmitButtonWrapper = styled.div`
  Button {
    width: 100%;
  }
`;

const CheckCircle = styled.div`
  background: blue;
  background: linear-gradient(45deg, #f861c2, #fbb391);
  display: inline-block;
  border-radius: 50%;
  padding: 1rem;
`;

const WarningCircle = styled.div`
  background: blue;
  background: linear-gradient(45deg, red, yellow);
  display: inline-block;
  border-radius: 50%;
  padding: 1.5rem;
`;

const CopyContent = styled.div`
  position: relative;
  width: 100%;
  height: 56px;
`;

const LinkField = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  outline: none;
  font-weight: 600;
  font-size: 16px;
`;

const CopyText = styled.span`
  color: #000;
  font-size: 15px;
  font-weight: 600;
`;

const CopyButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  width: 85px;
  height: 56px;
  outline: none;
  border: none;
  font-weight: 600;
  cursor: pointer;
  font-size: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: ${(props) => (props.isCopied ? "#00D8C4" : "#00f4de")};
`;

const SocialIconBar = styled.div`
  display: flex;
  margin-top: 20px;
`;

const SocialIcon = styled.img`
  width: 48px;
  height: 48px;
`;

const IconDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 30px;
`;

const SocialIconWrapper = styled.div`
  margin-right: 24px;
`;
